<template>
  <div>
    <video autoplay muted loop class="solution-selector-vid">
      <source
        :src="require('@/assets/SolutionSelector.mp4')"
        type="video/mp4"
      />
    </video>
    <!-- <iframe class="solution-selector-vid" src="https://www.youtube.com/embed/HIVYssngYxI" title="Firelog" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->
    <v-card flat tile color="transparent" class="login-content">
      <v-card-title>
        <!-- <img
          class="shrink mr-2"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          width="40"
        />
        <h1 class="text-light-blue">Solution selector</h1> -->
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row no-gutters>
            <!-- <v-col cols="12" sm="8">
                <v-card tile height="100%" color="light blue" flat>
                  <v-card-title>{{ $t("solutionselector") }}</v-card-title>
                  <v-card-text> Log in to the Solution Selector </v-card-text>
                </v-card>
              </v-col> -->
            <v-col cols="12" sm="12" lg="4" xl="3" offset="0" offset-xl="9">
              <v-card tile height="100%">
                <div class="pa-4 text-light-blue">
                  <div class="d-flex align-center justify-center">
                    <img
                      class="shrink mr-2"
                      contain
                      src="@/assets/logo.png"
                      transition="scale-transition"
                      width="40"
                    />
                    <h1>Solution selector</h1>
                    <div
                      v-if="$vuetify.breakpoint.mdOnly"
                      class="
                        text-light-blue
                        d-flex
                        align-center
                        justify-center
                        ml-4
                      "
                    >
                      <span class="">by</span>

                      <img
                        class="shrink mr-2 ml-1"
                        contain
                        src="@/assets/IsopartnerLogo.png"
                        transition="scale-transition"
                        width="200"
                      />
                    </div>
                  </div>
                  <!-- </v-card-title>
                <v-card-title class="text-light-blue"> -->
                  <div
                    v-if="
                      $vuetify.breakpoint.smAndDown ||
                      $vuetify.breakpoint.lgAndUp
                    "
                    class="text-light-blue d-flex align-center justify-center"
                  >
                    <span class="">by</span>

                    <img
                      class="shrink mr-2 ml-2"
                      contain
                      src="@/assets/IsopartnerLogo.png"
                      transition="scale-transition"
                      width="200"
                    />
                  </div>
                </div>
                <v-card-title>
                  {{ $t("login") }}
                </v-card-title>
                <v-card-text>
                  <v-form
                    id="log_in_form"
                    @submit.prevent="login"
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                    <v-text-field
                      filled
                      color="primary"
                      class="white--text"
                      v-model="credentials.username"
                      name="email"
                      :rules="emailRules()"
                      v-bind:label="$t('email')"
                      required
                    ></v-text-field>

                    <v-text-field
                      filled
                      autocomplete="new-password"
                      color="primary"
                      :type="show ? 'text' : 'password'"
                      v-model="credentials.inputP"
                      :rules="passwordRules()"
                      v-bind:label="$t('password')"
                      :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="show = !show"
                      required
                      @input="encrypt"
                    ></v-text-field>
                    <v-text-field
                      dense
                      outlined
                      color="primary"
                      class="d-none"
                      id="website"
                      v-model="credentials.honeypot"
                      required
                    ></v-text-field>
                    <v-checkbox v-model="disclaimer">
                      <template v-slot:label>
                        <div @click.stop="" class="disclaimer-block">
                          <span>{{ disclaimerLabel }}</span>
                          <a
                            @click.prevent="
                              disclaimerSeeMore = !disclaimerSeeMore
                            "
                            >{{
                              disclaimerSeeMore ? " Show less" : "... Show more"
                            }}</a
                          >
                        </div>
                      </template>
                    </v-checkbox>
                    <v-btn
                      block
                      x-large
                      type="submit"
                      color="primary"
                      class="mb-3"
                      :disabled="!disclaimer"
                      :loading="loading"
                      @click.prevent="login"
                    >
                      {{ $t("login") }}
                    </v-btn>
                    <v-btn
                      text
                      small
                      color="blue"
                      href="https://isopartner.nl/nl/contact"
                      target="_blank"
                    >
                      {{ $t("register") }}
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import jwtdecode from "jwt-decode";
import sha256 from "sha256";
// import service from "@/services/service.js";

export default {
  //   name: "LoginComponent",
  //   mounted() {
  //     let form = document.getElementById("log_in_form");
  //     var newScript = document.createElement("script");
  //     var inlineScript = document.createTextNode(
  //       "var __ss_noform = __ss_noform || [];\
  //     __ss_noform.push(['baseURI', 'https://app-3QNF04NCZU.marketingautomation.services/webforms/receivePostback/MzawMDGzMDO3BAA/']);\
  //     __ss_noform.push(['form','log_in_form', 'a2246a5c-b387-4bab-bf8a-a4fd11141522']);\
  //     __ss_noform.push(['submitType', 'manual']);"
  //     );
  //     newScript.appendChild(inlineScript);
  //     form.parentNode.insertBefore(newScript, form.nextSibling);

  //     let sharpspringElement = document.createElement("script");
  //     sharpspringElement.setAttribute(
  //       "src",
  //       "https://koi-3QNF04NCZU.marketingautomation.services/client/noform.js?ver=1.24"
  //     );
  //     newScript.parentNode.insertBefore(
  //       sharpspringElement,
  //       newScript.nextSibling
  //     );
  //   },
  components: {},
  data: () => {
    return {
      tab: null,
      show: false,
      loading: false,
      valid: false,
      credentials: {
        username: "",
        inputP: "",
        password: "",
        honeypot: "",
        ihubApplicationId: 2,
      },
      credentialsToSend: {},
      disclaimer: false,
      disclaimerSeeMore: false,
    };
  },
  methods: {
    // changeLanguage(language) {
    //   this.$i18n.locale = language;
    //   localStorage.setItem("language", language);
    // },
    // fromCodePoint(codepoint) {
    //   if (codepoint.split(" ").length > 0) {
    //     return (
    //       String.fromCodePoint(codepoint.split(" ")[0]) +
    //       String.fromCodePoint(codepoint.split(" ")[1])
    //     );
    //   }
    //   return String.fromCodePoint(codepoint);
    // },
    login() {
      if (this.valid && this.$refs.form.validate()) {
        if (this.credentials.honeypot.length == 0) {
          this.loading = true;
          this.encrypt();
          if (localStorage.getItem("language") == null) {
            localStorage.setItem("language", "EN");
          }
          //   __ss_noform.push([
          //     "submit",
          //     null,
          //     "a2246a5c-b387-4bab-bf8a-a4fd11141522",
          //   ]);
          // console.log("login", this.credentials);
          this.credentialsToSend = {};
          this.credentialsToSend.username = this.credentials.username;
          this.credentialsToSend.password = this.credentials.password;
          this.credentialsToSend.ihubApplicationId = 2;

          // console.log(this.credentialsToSend);

          this.$http
            .login(this.credentialsToSend)
            .then((res) => {
              if (res.data) {
                this.loginReturnedData(res);
              } else if (this.credentials.ihubApplicationId == 2) {
                this.credentials.ihubApplicationId = 3;
                this.credentialsToSend.ihubApplicationId = 3;
                //   console.log("ihubApplication == ", this.credentials.ihubApplicationId);
                this.loginAgain();
              } else {
                this.$noty.error(this.$t("userNotFound"));
                this.loading = false;
              }
            })
            .catch((err) => {
              this.$noty.error(this.$t("error"));
              console.log(err);
              this.loading = false;
            });
        }
      }
    },
    loginAgain() {
      if (this.valid && this.$refs.form.validate()) {
        if (this.credentials.honeypot.length == 0) {
          this.loading = true;
          this.encrypt();
          if (localStorage.getItem("language") == null) {
            localStorage.setItem("language", "EN");
          }
          //   __ss_noform.push([
          //     "submit",
          //     null,
          //     "a2246a5c-b387-4bab-bf8a-a4fd11141522",
          //   ]);
          // console.log("login again", this.credentials)
          this.$http
            .login(this.credentialsToSend)
            .then((res) => {
              // console.log(res)
              if (res.data) {
                this.loginReturnedData(res);
              } else {
                this.$noty.error(this.$t("userNotFound"));
                this.loading = false;
              }
            })
            .catch((err) => {
              this.$noty.error(this.$t("error"));
              console.log(err);
              this.loading = false;
            });
        }
      }
    },
    loginReturnedData(res) {
      if (jwtdecode(res.data)["QRLOG"]) {
        this.$noty.success(this.$t("success"));
        localStorage.setItem("auth", res.data);
        localStorage.setItem("user", jwtdecode(res.data).name);
        this.$store.state.loggedIn = true;
        this.loading = false;
        this.$router.push({ name: "Home" });
      } else {
        this.$noty.error(this.$t("noaccess"));
        this.loading = false;
      }
    },
    emailRules() {
      return [
        (v) => !!v || `${this.$t("email")} ${this.$t("required")}`,
        (v) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || `${this.$t("email")} ${this.$t("invalid")}`;
        },
      ];
    },
    passwordRules() {
      return [(v) => !!v || `${this.$t("password")} ${this.$t("required")}`];
    },
    encrypt() {
      // console.log("encrypting", this.credentials);

      this.credentials.password = sha256(this.credentials.inputP);
    },
  },
  computed: {
    disclaimerLabel() {
      const text =
        "We rely on product information as collected from manufacturers and/or other parties that make the concerned product(s) available. As such, we are not responsible for the content thereof. Any use should always be made with prior reference to the original product information and user guidance. We accept no liability for use of this tool, except in cases of gross negligence or wilful misconduct.";
      if (this.disclaimerSeeMore) {
        return text;
      } else {
        return text.substring(0, 50);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.solution-selector-vid {
  display: none;
}

@media only screen and (min-width: 600px) {
  /* start breakpoint sm */
}
@media only screen and (min-width: 641px) and (orientation: portrait) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .solution-selector-vid {
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

@media only screen and (min-width: 960px) {
  /* start breakpoint md */
  .solution-selector-vid {
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    // width: auto;
    // min-width: 100%;
    // min-height: 100%;
    // transform: translate(-25%, 25%);
  }
}
@media only screen and (min-width: 1264px) {
  /* start breakpoint lg */
  .solution-selector-vid {
    position: absolute;
    left: 0;
    bottom: 0;
    width: auto;
    min-width: 100%;
    min-height: 100%;
    // transform: translate(0%, 0%);
  }
}
@media only screen and (min-width: 1904px) {
  /* start breakpoint xl */
}

.login-content {
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  min-width: 100%;
  // padding: 20px;
}

.small-screen {
  // transform: translate(-25%, 25%);
  transform: translate(-25%, 25%);
}

.disclaimer-block {
  max-height: 80px;
  overflow: auto;
}

.text-light-blue {
  color: #069ad8;
}
</style>
